.hero {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 4rem;
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}


.hero__text {
    min-height: 100%;
    padding: 0 4rem;
    font-family: 'Raleway';
    color: #003330;
}

.hero__text h1 {
    font-size: 3.2rem;
    max-width: 550px;
    line-height: 133%;
}

.hero__text strong {
    background: #00B2AD;
    color: #003330;
    font-weight: 600;
}

.hero__btn {
    display: block;
    margin-top: 4rem;
    text-decoration: none;
    color: #003330;
    padding: 1rem 0.5rem;
    width: 200px;
    text-align: center;
    font-weight: 600;
    box-shadow: 0.3em 0.3em 0 0 #00B2AD, inset 0.3em 0.3em 0 0 #00B2AD;
    transition: all 130ms ease-out;
}

.hero__btn:hover, .hero__btn:focus {
    box-shadow: 
      0 0 0 0 #00B2AD,
      inset 6em 3.5em 0 0 #00B2AD;
    color: #F2F9F9;
}



@media (max-width: 800px) {
    
    .hero__text {
        padding-left: 2rem;
    }
}

@media (max-width: 550px) {
    .hero {
        flex-direction: column; 
    }
    #hero__bg {
        margin: 0;
        margin-top: 12px;
        max-height: 350px;
        max-width: 350px;
    }
    .hero__btn {
        margin-top: 2rem;
    }
    .hero__text h1 {
        font-size: 2rem;
    }

    
}