#services {
    min-height: 100vh;
}

.service__card {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
}

.service__card img {
    width: 80px;
    padding-bottom: 1rem;
}

.service__card span {
    padding: 1rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-family: 'Raleway';
    text-align: center;
    color: #003330;
}

.service__card span strong {
    font-weight: bold;
    color: #00B2AD;
}

@media (max-width: 550px) {
    .service__card {
        width: 80%;
    }
}