header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
}

header::before,
header::after {
    content: '';
    flex: 1 1 auto;
    border-bottom: solid 15px #00B2AD;
}

.header__logo {
    max-width: 200px;
    margin: 0 1rem;
}
