body {
    background-color: #F2F9F9;
}

.landing {
    min-height: 100vh;
}

.services__section {
    display: flex;
    justify-content: space-evenly;
}

#services h2 {
    padding: 3rem 1rem;
    text-align: center;
    font-family: 'Raleway';
    font-size: 3rem;
    font-weight: 600;
    color: #003330;
}

@media (max-width: 550px) {
    .services__section {
        flex-direction: column;
        align-items: center;
    }
    
}