footer {
    background-color: #00B2AD;
    color: #F2F9F9;
    /* transform: skewY(-5deg); */
    width: 100%;
    margin-top: 2rem;
    clip-path: polygon(
    0 0, /* left top */
    100% 12%, /* right top */ 
    100% 100%, /* right bottom */
    0 100% /* left bottom */
  );
}

footer h3, footer h4 {
    text-align: center;
    font-family: 'Raleway';
}

footer h3 {
    font-weight: 600;
    padding: 4rem 1rem;
    font-size: 2.2rem;
}

footer h4 {
    font-size: 1.2rem;
    max-width: 600px;
    padding: 0 1rem;
    display: block;
    margin: 0 auto;
}

#footer__newsletter {
    display: block;
    width: fit-content;
    margin: 2rem auto 0 auto;
    padding-bottom: 4rem;
}

#email, #newsletter__btn {
    border: none;
    color: black;
    padding: 1rem;
    border: 2px solid #003330;
    
}

#email { 
    background: #F2F9F9;
    border-radius: 7px;
}

#newsletter__btn { 
     /* border-left: none; */
    background-color: #003330;
    border-radius: 7px;
    margin-left: 1rem;
    color: #D1EDEB;
    cursor: pointer;
    height: 52px;
    width: 150px;
    transition: all 120ms ease-out;
}

#newsletter__btn:hover {
    color: #00B2AD;
}

@media screen and (max-width : 400px) {
    #footer__newsletter {
        display: flex;
        flex-direction: column;
    } 
    #newsletter__btn {
        margin: 1rem auto;
    }
}